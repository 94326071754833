<template>
  <div class="app-container">
    <header-menu :showTitle="false" :menuItems="menuItems"> </header-menu>
    <div class="app-content-wrap">
      <router-view />
    </div>
    <footer-info></footer-info>
  </div>
</template>

<script>
const defaultMenu = [
  { title: "首页", path: "/" },
  { title: "新闻公告", path: "/information" },
  { title: "学习课程", path: "/course" },
  { title: "合作企业", path: "/cooperativeFirm" },
  { title: "企业岗位", path: "/recruitment" },
  { title: "成果展示", path: "/achievement" },
  // { title: "企业管理", path: "/enterprise" },
  // { title: "学校管理", path: "/school" },
  
]
import HeaderMenu from "@/components/Common/HeaderMenu";
import FooterInfo from "@/components/Common/FooterInfo";
import { mapGetters } from 'vuex';
import { StaffTypeEnum } from '@/utils/constant';
export default {
  components: { HeaderMenu, FooterInfo },
  data() {
    return {
      activeIndex: "0",
      // isLogin: false,
      // menuItems: [
      //   { title: "首页", path: "/" },
      //   { title: "新闻公告", path: "/information" },
      //   { title: "岗位招聘", path: "/recruitment" },
      //   { title: "培训课程", path: "/course" },
      //   { title: "合作企业", path: "/cooperativeFirm" },
      //   { title: "成果展示", path: "/achievement" },
      //   { title: "企业管理", path: "/enterprise" },
      //   { title: "学校管理", path: "/school" }
      // ],
      loaded: false
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    menuItems({ employee = [] }) {
      const { Employer: SchoolEmployer, IsTeacher: IsSchoolTeacher, IsAdmin: IsSchoolAdmin } = employee.find(({ StaffType }) => StaffType === StaffTypeEnum.School) || {};
      if (SchoolEmployer) {
        if (IsSchoolAdmin) {
          if (IsSchoolTeacher) {
            return [...defaultMenu, { title: "学校中心", path: "/school" }, { title: "项目班评价", path: "/arrangeEvaluate" }];
          } else {
            return [...defaultMenu, { title: "学校中心", path: "/school" }];
          }
        } else {
          if (IsSchoolTeacher) {
            return [...defaultMenu, { title: "项目班评价", path: "/arrangeEvaluate" }];
          } else {
            return defaultMenu;
          }
        }
      }
      const { Employer: EnterpriseEmployer, IsTeacher: IsEnterpriseTeacher, IsAdmin: IsEnterpriseAdmin } = employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
      if (EnterpriseEmployer) {
        if (IsEnterpriseAdmin) {
          if (IsEnterpriseTeacher) {
            return [...defaultMenu, { title: "企业中心", path: "/enterprise" }, { title: "项目班评价", path: "/arrangeEvaluate" }];
          } else {
            return [...defaultMenu, { title: "企业中心", path: "/enterprise" }];
          }
        } else {
          if (IsEnterpriseTeacher) {
            return [...defaultMenu, { title: "项目班评价", path: "/arrangeEvaluate" }];
          } else {
            return defaultMenu;
          }
        }
      }
      return defaultMenu;
    }
  },
  async mounted() {
  },
  methods: {
    handleSelectMenu(key, keyPath) {
      console.log(key, keyPath);
      if (key === "home") {
        this.$router.push(`/`);
      } else {
        this.$router.push(`/${key}`);
      }
    },

    handleResume() {
      this.$router.push("/person/home");
    }
  }
};
</script>

<style lang="scss" scoped></style>
